
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "es",
  "it"
]

export const localeLoaders = {
  "nl": [{ key: "../src/locales/nl.json", load: () => import("../src/locales/nl.json" /* webpackChunkName: "locale__app_src_locales_nl_json" */), cache: true }],
  "es": [{ key: "../src/locales/es.json", load: () => import("../src/locales/es.json" /* webpackChunkName: "locale__app_src_locales_es_json" */), cache: true }],
  "it": [{ key: "../src/locales/it.json", load: () => import("../src/locales/it.json" /* webpackChunkName: "locale__app_src_locales_it_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "nl",
      "iso": "nl",
      "dir": "ltr",
      "domain": "https://lambwestonathome.nl",
      "files": [
        "locales/nl.json"
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "dir": "ltr",
      "domain": "https://lambwestonathome.es",
      "files": [
        "locales/es.json"
      ]
    },
    {
      "code": "it",
      "iso": "it",
      "dir": "ltr",
      "domain": "https://lambwestonathome.it",
      "files": [
        "locales/it.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl",
    "iso": "nl",
    "dir": "ltr",
    "domain": "https://lambwestonathome.nl",
    "files": [
      {
        "path": "locales/nl.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es",
    "dir": "ltr",
    "domain": "https://lambwestonathome.es",
    "files": [
      {
        "path": "locales/es.json"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it",
    "dir": "ltr",
    "domain": "https://lambwestonathome.it",
    "files": [
      {
        "path": "locales/it.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
