import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as _91product_93_45campaignpz5CdX4rZCMeta } from "/app/src/pages/[product]-campaign.vue?macro=true";
import { default as index6dle6k33gZMeta } from "/app/src/pages/about-us/index.vue?macro=true";
import { default as contactmqHVTjI8koMeta } from "/app/src/pages/contact.vue?macro=true";
import { default as cookiesljJJ99na9gMeta } from "/app/src/pages/cookies.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91slug_93hwEhpzXwWtMeta } from "/app/src/pages/inspiration/[slug].vue?macro=true";
import { default as indexwWiL1Z4orWMeta } from "/app/src/pages/inspiration/index.vue?macro=true";
import { default as _91slug_93D9p4qGXTBQMeta } from "/app/src/pages/products/[slug].vue?macro=true";
import { default as indexwnrgOfBd2dMeta } from "/app/src/pages/products/index.vue?macro=true";
import { default as _91slug_93bOuhFHXO95Meta } from "/app/src/pages/recipes/[slug].vue?macro=true";
import { default as shop_45finderbA7qcl6TxnMeta } from "/app/src/pages/shop-finder.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug___nl___default",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug___nl",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug___es",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug___it",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91product_93_45campaignpz5CdX4rZCMeta?.name ?? "product-campaign___nl___default",
    path: _91product_93_45campaignpz5CdX4rZCMeta?.path ?? "/:product()-campagne",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    alias: _91product_93_45campaignpz5CdX4rZCMeta?.alias || [],
    redirect: _91product_93_45campaignpz5CdX4rZCMeta?.redirect,
    component: () => import("/app/src/pages/[product]-campaign.vue").then(m => m.default || m)
  },
  {
    name: _91product_93_45campaignpz5CdX4rZCMeta?.name ?? "product-campaign___nl",
    path: _91product_93_45campaignpz5CdX4rZCMeta?.path ?? "/:product()-campagne",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    alias: _91product_93_45campaignpz5CdX4rZCMeta?.alias || [],
    redirect: _91product_93_45campaignpz5CdX4rZCMeta?.redirect,
    component: () => import("/app/src/pages/[product]-campaign.vue").then(m => m.default || m)
  },
  {
    name: _91product_93_45campaignpz5CdX4rZCMeta?.name ?? "product-campaign___es",
    path: _91product_93_45campaignpz5CdX4rZCMeta?.path ?? "/:product()-campana",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    alias: _91product_93_45campaignpz5CdX4rZCMeta?.alias || [],
    redirect: _91product_93_45campaignpz5CdX4rZCMeta?.redirect,
    component: () => import("/app/src/pages/[product]-campaign.vue").then(m => m.default || m)
  },
  {
    name: _91product_93_45campaignpz5CdX4rZCMeta?.name ?? "product-campaign___it",
    path: _91product_93_45campaignpz5CdX4rZCMeta?.path ?? "/:product()-campagna",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    alias: _91product_93_45campaignpz5CdX4rZCMeta?.alias || [],
    redirect: _91product_93_45campaignpz5CdX4rZCMeta?.redirect,
    component: () => import("/app/src/pages/[product]-campaign.vue").then(m => m.default || m)
  },
  {
    name: index6dle6k33gZMeta?.name ?? "about-us___nl___default",
    path: index6dle6k33gZMeta?.path ?? "/over-ons",
    meta: index6dle6k33gZMeta || {},
    alias: index6dle6k33gZMeta?.alias || [],
    redirect: index6dle6k33gZMeta?.redirect,
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: index6dle6k33gZMeta?.name ?? "about-us___nl",
    path: index6dle6k33gZMeta?.path ?? "/over-ons",
    meta: index6dle6k33gZMeta || {},
    alias: index6dle6k33gZMeta?.alias || [],
    redirect: index6dle6k33gZMeta?.redirect,
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: index6dle6k33gZMeta?.name ?? "about-us___es",
    path: index6dle6k33gZMeta?.path ?? "/sobre-nosotros",
    meta: index6dle6k33gZMeta || {},
    alias: index6dle6k33gZMeta?.alias || [],
    redirect: index6dle6k33gZMeta?.redirect,
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: index6dle6k33gZMeta?.name ?? "about-us___it",
    path: index6dle6k33gZMeta?.path ?? "/chi-siamo",
    meta: index6dle6k33gZMeta || {},
    alias: index6dle6k33gZMeta?.alias || [],
    redirect: index6dle6k33gZMeta?.redirect,
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: contactmqHVTjI8koMeta?.name ?? "contact___nl___default",
    path: contactmqHVTjI8koMeta?.path ?? "/contact",
    meta: contactmqHVTjI8koMeta || {},
    alias: contactmqHVTjI8koMeta?.alias || [],
    redirect: contactmqHVTjI8koMeta?.redirect,
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmqHVTjI8koMeta?.name ?? "contact___nl",
    path: contactmqHVTjI8koMeta?.path ?? "/contact",
    meta: contactmqHVTjI8koMeta || {},
    alias: contactmqHVTjI8koMeta?.alias || [],
    redirect: contactmqHVTjI8koMeta?.redirect,
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmqHVTjI8koMeta?.name ?? "contact___es",
    path: contactmqHVTjI8koMeta?.path ?? "/contacto",
    meta: contactmqHVTjI8koMeta || {},
    alias: contactmqHVTjI8koMeta?.alias || [],
    redirect: contactmqHVTjI8koMeta?.redirect,
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmqHVTjI8koMeta?.name ?? "contact___it",
    path: contactmqHVTjI8koMeta?.path ?? "/contattaci",
    meta: contactmqHVTjI8koMeta || {},
    alias: contactmqHVTjI8koMeta?.alias || [],
    redirect: contactmqHVTjI8koMeta?.redirect,
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cookiesljJJ99na9gMeta?.name ?? "cookies___nl___default",
    path: cookiesljJJ99na9gMeta?.path ?? "/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    alias: cookiesljJJ99na9gMeta?.alias || [],
    redirect: cookiesljJJ99na9gMeta?.redirect,
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesljJJ99na9gMeta?.name ?? "cookies___nl",
    path: cookiesljJJ99na9gMeta?.path ?? "/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    alias: cookiesljJJ99na9gMeta?.alias || [],
    redirect: cookiesljJJ99na9gMeta?.redirect,
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesljJJ99na9gMeta?.name ?? "cookies___es",
    path: cookiesljJJ99na9gMeta?.path ?? "/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    alias: cookiesljJJ99na9gMeta?.alias || [],
    redirect: cookiesljJJ99na9gMeta?.redirect,
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesljJJ99na9gMeta?.name ?? "cookies___it",
    path: cookiesljJJ99na9gMeta?.path ?? "/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    alias: cookiesljJJ99na9gMeta?.alias || [],
    redirect: cookiesljJJ99na9gMeta?.redirect,
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___nl___default",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___nl",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___es",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___it",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hwEhpzXwWtMeta?.name ?? "inspiration-slug___nl___default",
    path: _91slug_93hwEhpzXwWtMeta?.path ?? "/inspiratie/\\:slug",
    meta: _91slug_93hwEhpzXwWtMeta || {},
    alias: _91slug_93hwEhpzXwWtMeta?.alias || [],
    redirect: _91slug_93hwEhpzXwWtMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hwEhpzXwWtMeta?.name ?? "inspiration-slug___nl",
    path: _91slug_93hwEhpzXwWtMeta?.path ?? "/inspiratie/\\:slug",
    meta: _91slug_93hwEhpzXwWtMeta || {},
    alias: _91slug_93hwEhpzXwWtMeta?.alias || [],
    redirect: _91slug_93hwEhpzXwWtMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hwEhpzXwWtMeta?.name ?? "inspiration-slug___es",
    path: _91slug_93hwEhpzXwWtMeta?.path ?? "/inspiracion/\\:slug",
    meta: _91slug_93hwEhpzXwWtMeta || {},
    alias: _91slug_93hwEhpzXwWtMeta?.alias || [],
    redirect: _91slug_93hwEhpzXwWtMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hwEhpzXwWtMeta?.name ?? "inspiration-slug___it",
    path: _91slug_93hwEhpzXwWtMeta?.path ?? "/ispirazione/\\:slug",
    meta: _91slug_93hwEhpzXwWtMeta || {},
    alias: _91slug_93hwEhpzXwWtMeta?.alias || [],
    redirect: _91slug_93hwEhpzXwWtMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwWiL1Z4orWMeta?.name ?? "inspiration___nl___default",
    path: indexwWiL1Z4orWMeta?.path ?? "/inspiratie",
    meta: indexwWiL1Z4orWMeta || {},
    alias: indexwWiL1Z4orWMeta?.alias || [],
    redirect: indexwWiL1Z4orWMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexwWiL1Z4orWMeta?.name ?? "inspiration___nl",
    path: indexwWiL1Z4orWMeta?.path ?? "/inspiratie",
    meta: indexwWiL1Z4orWMeta || {},
    alias: indexwWiL1Z4orWMeta?.alias || [],
    redirect: indexwWiL1Z4orWMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexwWiL1Z4orWMeta?.name ?? "inspiration___es",
    path: indexwWiL1Z4orWMeta?.path ?? "/inspiracion",
    meta: indexwWiL1Z4orWMeta || {},
    alias: indexwWiL1Z4orWMeta?.alias || [],
    redirect: indexwWiL1Z4orWMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexwWiL1Z4orWMeta?.name ?? "inspiration___it",
    path: indexwWiL1Z4orWMeta?.path ?? "/ispirazione",
    meta: indexwWiL1Z4orWMeta || {},
    alias: indexwWiL1Z4orWMeta?.alias || [],
    redirect: indexwWiL1Z4orWMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D9p4qGXTBQMeta?.name ?? "products-slug___nl___default",
    path: _91slug_93D9p4qGXTBQMeta?.path ?? "/producten/:slug()",
    meta: _91slug_93D9p4qGXTBQMeta || {},
    alias: _91slug_93D9p4qGXTBQMeta?.alias || [],
    redirect: _91slug_93D9p4qGXTBQMeta?.redirect,
    component: () => import("/app/src/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D9p4qGXTBQMeta?.name ?? "products-slug___nl",
    path: _91slug_93D9p4qGXTBQMeta?.path ?? "/producten/:slug()",
    meta: _91slug_93D9p4qGXTBQMeta || {},
    alias: _91slug_93D9p4qGXTBQMeta?.alias || [],
    redirect: _91slug_93D9p4qGXTBQMeta?.redirect,
    component: () => import("/app/src/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D9p4qGXTBQMeta?.name ?? "products-slug___es",
    path: _91slug_93D9p4qGXTBQMeta?.path ?? "/productos/:slug()",
    meta: _91slug_93D9p4qGXTBQMeta || {},
    alias: _91slug_93D9p4qGXTBQMeta?.alias || [],
    redirect: _91slug_93D9p4qGXTBQMeta?.redirect,
    component: () => import("/app/src/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D9p4qGXTBQMeta?.name ?? "products-slug___it",
    path: _91slug_93D9p4qGXTBQMeta?.path ?? "/prodotti/:slug()",
    meta: _91slug_93D9p4qGXTBQMeta || {},
    alias: _91slug_93D9p4qGXTBQMeta?.alias || [],
    redirect: _91slug_93D9p4qGXTBQMeta?.redirect,
    component: () => import("/app/src/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwnrgOfBd2dMeta?.name ?? "products___nl___default",
    path: indexwnrgOfBd2dMeta?.path ?? "/producten",
    meta: indexwnrgOfBd2dMeta || {},
    alias: indexwnrgOfBd2dMeta?.alias || [],
    redirect: indexwnrgOfBd2dMeta?.redirect,
    component: () => import("/app/src/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnrgOfBd2dMeta?.name ?? "products___nl",
    path: indexwnrgOfBd2dMeta?.path ?? "/producten",
    meta: indexwnrgOfBd2dMeta || {},
    alias: indexwnrgOfBd2dMeta?.alias || [],
    redirect: indexwnrgOfBd2dMeta?.redirect,
    component: () => import("/app/src/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnrgOfBd2dMeta?.name ?? "products___es",
    path: indexwnrgOfBd2dMeta?.path ?? "/productos",
    meta: indexwnrgOfBd2dMeta || {},
    alias: indexwnrgOfBd2dMeta?.alias || [],
    redirect: indexwnrgOfBd2dMeta?.redirect,
    component: () => import("/app/src/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnrgOfBd2dMeta?.name ?? "products___it",
    path: indexwnrgOfBd2dMeta?.path ?? "/prodotti",
    meta: indexwnrgOfBd2dMeta || {},
    alias: indexwnrgOfBd2dMeta?.alias || [],
    redirect: indexwnrgOfBd2dMeta?.redirect,
    component: () => import("/app/src/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bOuhFHXO95Meta?.name ?? "recipes-slug___nl___default",
    path: _91slug_93bOuhFHXO95Meta?.path ?? "/recepten/:slug()",
    meta: _91slug_93bOuhFHXO95Meta || {},
    alias: _91slug_93bOuhFHXO95Meta?.alias || [],
    redirect: _91slug_93bOuhFHXO95Meta?.redirect,
    component: () => import("/app/src/pages/recipes/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bOuhFHXO95Meta?.name ?? "recipes-slug___nl",
    path: _91slug_93bOuhFHXO95Meta?.path ?? "/recepten/:slug()",
    meta: _91slug_93bOuhFHXO95Meta || {},
    alias: _91slug_93bOuhFHXO95Meta?.alias || [],
    redirect: _91slug_93bOuhFHXO95Meta?.redirect,
    component: () => import("/app/src/pages/recipes/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bOuhFHXO95Meta?.name ?? "recipes-slug___es",
    path: _91slug_93bOuhFHXO95Meta?.path ?? "/recetas/:slug()",
    meta: _91slug_93bOuhFHXO95Meta || {},
    alias: _91slug_93bOuhFHXO95Meta?.alias || [],
    redirect: _91slug_93bOuhFHXO95Meta?.redirect,
    component: () => import("/app/src/pages/recipes/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bOuhFHXO95Meta?.name ?? "recipes-slug___it",
    path: _91slug_93bOuhFHXO95Meta?.path ?? "/ricette/:slug()",
    meta: _91slug_93bOuhFHXO95Meta || {},
    alias: _91slug_93bOuhFHXO95Meta?.alias || [],
    redirect: _91slug_93bOuhFHXO95Meta?.redirect,
    component: () => import("/app/src/pages/recipes/[slug].vue").then(m => m.default || m)
  },
  {
    name: shop_45finderbA7qcl6TxnMeta?.name ?? "shop-finder___nl___default",
    path: shop_45finderbA7qcl6TxnMeta?.path ?? "/vind-jouw-twister-fry",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    alias: shop_45finderbA7qcl6TxnMeta?.alias || [],
    redirect: shop_45finderbA7qcl6TxnMeta?.redirect,
    component: () => import("/app/src/pages/shop-finder.vue").then(m => m.default || m)
  },
  {
    name: shop_45finderbA7qcl6TxnMeta?.name ?? "shop-finder___nl",
    path: shop_45finderbA7qcl6TxnMeta?.path ?? "/vind-jouw-twister-fry",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    alias: shop_45finderbA7qcl6TxnMeta?.alias || [],
    redirect: shop_45finderbA7qcl6TxnMeta?.redirect,
    component: () => import("/app/src/pages/shop-finder.vue").then(m => m.default || m)
  },
  {
    name: shop_45finderbA7qcl6TxnMeta?.name ?? "shop-finder___es",
    path: shop_45finderbA7qcl6TxnMeta?.path ?? "/encuentra-tu-twister-fry",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    alias: shop_45finderbA7qcl6TxnMeta?.alias || [],
    redirect: shop_45finderbA7qcl6TxnMeta?.redirect,
    component: () => import("/app/src/pages/shop-finder.vue").then(m => m.default || m)
  },
  {
    name: shop_45finderbA7qcl6TxnMeta?.name ?? "shop-finder___it",
    path: shop_45finderbA7qcl6TxnMeta?.path ?? "/trova-il-tuo-twister-fry",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    alias: shop_45finderbA7qcl6TxnMeta?.alias || [],
    redirect: shop_45finderbA7qcl6TxnMeta?.redirect,
    component: () => import("/app/src/pages/shop-finder.vue").then(m => m.default || m)
  }
]